<script>
	import { names, counties, namesToShow } from "../stores";
	import debounce from "lodash.debounce";
	import { fireEvent } from "../utils/analytics";
	import { instructions } from "../content/data.json";
	import { format } from "d3-format";

	import Button from "./Button.svelte";
	import InputText from "./InputText.svelte";
	import InputSelect from "./InputSelect.svelte";

	import MagnifyingGlass from "../icons/MagnifyingGlass.svelte";
	import Clear from "../icons/Clear.svelte";
	export let event;
	export let count = 0;
	let nameInput;
	let countyInput;

	const ALL_COUNTIES = "all-counties";
	let showAll = { label: "Show all counties", value: ALL_COUNTIES };
	let options = $counties
		.map(c => {
			return { label: c, value: c };
		})
		.sort((a, b) => (a.label > b.label ? 1 : -1));

	// This is the value holding the text search input
	let nameSearch = "";

	$: countySearch = ALL_COUNTIES;
	$: searching = nameSearch === "" && countySearch != ALL_COUNTIES;

	function handleSearch(e) {
		searching = true;
		$namesToShow = $names.filter(
			n =>
				n.name_display.toLowerCase().indexOf(nameSearch.toLowerCase()) > -1 &&
				(n.county === countySearch || countySearch === ALL_COUNTIES)
		);
	}

	function resetSearch(e) {
		fireEvent(`${event}-search-reset`);
		searching = false;
		countyInput.value = ALL_COUNTIES;
		resetNameSearch();
	}

	function resetNameSearch() {
		nameSearch = "";
		handleSearch(null);
	}

	handleSearch = debounce(handleSearch, 100);

	const handleNameSearch = debounce(function (e) {
		fireEvent(`${event}-name-search`);
	}, 500);
</script>

<div class="max-width">
	<p>{instructions}</p>
</div>
<div class="filters">
	<div class="field field--name">
		<InputText
			id="nameSearch"
			label="Search by name"
			bind:value={nameSearch}
			bind:this={nameInput}
			buttonClearFunction={resetNameSearch}
			buttonClearTitle="Clear this search"
			on:input={e => {
				handleNameSearch(e);
				handleSearch(e);
			}}
		/>
	</div>
	<div class="field field--counties">
		<InputSelect
			id="countySearch"
			label="Filter by county"
			bind:value={countySearch}
			bind:this={countyInput}
			on:input={handleSearch}
			on:input={e => {
				fireEvent(`${event}-county-search`);
			}}
			{options}
			{showAll}
		/>
	</div>
	<Button label="Clear" muted={!searching} on:click={resetSearch} />
</div>
{#if count > 1}
	<p>Now showing {format(",")(count)} people</p>
{:else if count === 1}
	<p>Now showing 1 person</p>
{/if}

<style>
	.filters {
		font: bold 13px/1.3em var(--fonts-sans-serif);
		display: grid;
		grid-template: auto / 1fr 100px;
		grid-gap: 15px;
		text-align: left;
		color: var(--covits-text-color);
	}

	.field--name {
		grid-column: 1 / -1;
		width: 100%;
	}

	.filters :global(.btn) {
		width: 100%;
		align-self: end;
	}

	@media all and (min-width: 768px) {
		.filters {
			grid-template: 1fr / 1fr 1fr 100px;
		}

		.field--name {
			grid-column: auto;
		}
	}
</style>
