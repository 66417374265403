<script>
	export let title = "Link opens in a new window";
</script>

<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80">
	<title>{title}</title>
	<path
		d="M65.79,8,36.56,37.22a4.41,4.41,0,1,0,6.23,6.23L72,14.23V24a4,4,0,0,0,8,0V4a4,4,0,0,0-4-4H56a4,4,0,0,0,0,8ZM80,48V70c0,5.51-4,10-8.87,10H8.87C4,80,0,75.53,0,70V10C0,4.46,4,0,8.87,0H32a4,4,0,0,1,0,8H10.28A2.49,2.49,0,0,0,8,10.67V69.33A2.51,2.51,0,0,0,10.28,72H69.72A2.49,2.49,0,0,0,72,69.33V48a4,4,0,0,1,8,0Z"
		style="fill-rule:evenodd" />
</svg>
