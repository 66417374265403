<script>
	// UTILS
	import { onMount } from "svelte";
	import * as analytics from "./utils/analytics.js";
	import { apdate } from "journalize";
	import { inView } from "./utils/watch-for-element.js";

	import { names, namesToShow } from "./stores";
	import { base } from "./utils/links";
	import { processText } from "./utils/process-text";

	// COMPONENTS
	import Names from "./components/Names.svelte";
	import Filters from "./components/Filters.svelte";
	import Modal from "./components/Modal.svelte";
	import Flourish from "./components/Flourish.svelte";
	import LoadingDashCircle from "./icons/LoadingDashCircle.svelte";
	import Button from "./components/Button.svelte";

	export let headline;
	export let intro;
	export let submit_label = "Submit a name";
	export let submit_link = "#";
	export let no_names_label = "No names.";
	export let tag = "";
	export let next = "";

	// Update date, to be filled in by the data file
	let updated;
	$: updateFormatted = apdate(new Date(updated));

	// HEALTHCARE VARIATION
	export let healthcare_title = "";
	export let healthcare_headline = "";
	export let healthcare_subheadline = "";
	export let healthcare_intro = "";
	export let healthcare_next = "";

	let obits;
	let { ASSET_PATH } = base();

	// Handle the different file names
	const data = {
		all: "northjersey.json",
		healthcare: "northjersey-healthcare.json",
	};

	let modal;
	function openModal(e) {
		modal.open(e.detail);
	}

	// Get the key for which names we want to see, defaulting to "all"
	let namesKey = "all";
	// Make our events match the covits variant we are making
	$: event = `storytelling-covid-obits-${data[namesKey].replace(".json", "")}`;
	$: title = namesKey === "healthcare" ? healthcare_title : title;
	$: headline = namesKey === "healthcare" ? healthcare_headline : headline;
	$: subheadline = namesKey === "healthcare" ? healthcare_subheadline : subheadline;
	$: intro = namesKey === "healthcare" ? healthcare_intro : intro;
	$: next = namesKey === "healthcare" ? healthcare_next : next;

	onMount(() => {
		// To know if we are standalone or inside a next up ...
		// Good for all except IE ... https://developer.mozilla.org/en-US/docs/Web/API/Element/closest
		// Closest() returns a node if it finds one and null if it does not. We will then fire a
		// page view when this interactive is scrolled into view.
		if (obits.closest(".next-in-depth-story")) {
			inView({
				element: obits,
				cb: analytics.firePageView,
			});
		}

		// Find which data source we want
		if (obits.parentNode.dataset.namesKey && data[obits.parentNode.dataset.namesKey]) {
			namesKey = obits.parentNode.dataset.namesKey;
		}

		// Double check we have a filename, too
		if (!data[namesKey]) namesKey = "all";
		const dataUrl = new URL(data[namesKey], ASSET_PATH);
		dataUrl.searchParams.set("d", Date.now());

		// Get the names and make it visible
		fetch(dataUrl.toString())
			.then(resp => resp.json())
			.then(data => {
				updated = data.updated;
				$names = data.names;
				$namesToShow = data.names;
			});
	});
</script>

<div class="obits" bind:this={obits}>
	<div class="obits__flourish">
		<Flourish />
	</div>
	<div class="obits__intro max-width">
		<h1 class="hede">{headline}</h1>
		<p>{@html processText(intro)}</p>
		{#if updated}
			<p class="updated">
				Updated: <time datetime={updated}>{updateFormatted}</time>
			</p>
		{/if}
		{#if submit_link}
			<Button
				id="submit"
				href={submit_link}
				label={submit_label}
				on:click={() => {
					analytics.fireEvent(`${event}-submit-name`);
				}}
			/>
		{/if}
	</div>
	{#if $names.length > 0}
		<Filters {event} count={$namesToShow.length} />
		<Names {event} {no_names_label} on:modal:open={openModal} />
	{:else}
		<LoadingDashCircle width="50px" />
	{/if}
	{#if tag}
		<p>{tag}</p>
	{/if}
</div>
<Modal bind:this={modal} {event} />
{#if next}
	<div data-storytelling-component="next-in-depth-story" data-next-asset-id={next} />
{/if}

<style>
	:global(#covid-obituaries-names) {
		--color-gold: #fed874;
		--color-grey: #aaa;
		--color-accent: var(--color-gold, #fed874);
		--color-accent-text: var(--theme-color-text, black);
		--color-name: #555555;

		--fonts: "Georgia", "Times New Roman", serif;
		--fonts-sans-serif: "Unify Sans", "Helvetica", "Arial", sans-serif;
		--nav-height: 50px;
		--border-radius: 5px;
		--input-height: 44px;

		--covits-background-color: #222;
		--covits-text-color: white;

		/* UW */
		background: var(--covits-background-color, #222);
	}

	:global(html .generic-content-container) {
		/* Another UW fix */
		--color-background: var(--covits-background-color, #222);
	}

	:global(#covid-obituaries-names) {
		width: 100vw;
		margin-left: calc(-1 * var(--article-padding-horiz));
	}

	.obits {
		background: var(--covits-background-color);
		margin: 60px 0;
		box-sizing: border-box;
		padding: 30px;
		text-align: center;
		max-width: 1300px;
		margin: 0 auto;
	}

	.obits :global(p) {
		color: var(--covits-text-color);
	}

	.obits :global(a:not([class])) {
		color: var(--covits-text-color, white);
		text-decoration-color: var(--color-accent, #fed874);
	}

	:global(.max-width) {
		max-width: 750px;
		margin: 0 auto;
	}

	.obits__flourish {
		width: 75px;
		margin: 0 auto 16px auto;
	}

	.obits__flourish :global(svg) {
		fill: var(--color-accent);
	}

	.obits .hede {
		font-family: var(--fonts);
		font-style: italic;
		font-weight: normal;
		font-size: var(--type-2, calc(20px + 8 * (100vw - 320px) / 880));
		color: var(--covits-text-color) !important; /* UW styles */
	}

	.obits :global(p:not([class])) {
		font: 16px/1.2em var(--fonts-sans-serif);
		margin: 1em 0;
		color: var(--covits-text-color);
	}
	.obits :global(.spinner) {
		fill: var(--covits-text-color);
	}

	.updated {
		font: bold 14px/1em var(--fonts-sans-serif);
		margin: 2em 0;
	}

	/* SOME TWEAKS FOR THE POTENTIAL EMBEDDED VERSION */
	:global(.next-in-depth-story #covid-obituaries-names) {
		width: unset;
		margin-left: unset;
		padding-top: 40px;
	}

	:global(div[data-storytelling-component="next-in-depth-story"]) {
		margin-bottom: 0;
	}

	@media all and (min-width: 1024px) {
		.obits__flourish {
			width: 110px;
		}
	}
</style>
