<script>
	export let title = "Clear";
</script>

<svg
	width="18"
	height="18"
	viewBox="0 0 18 18"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
	role="img">
	<title>{title}</title>
	<path
		fill-rule="evenodd"
		clip-rule="evenodd"
		d="M12.1692 6.8266C12.6223 6.37207 12.4753 5.90719 12.2603 5.69147C12.0453 5.47576
		11.5819 5.32828 11.1289 5.7828L8.9791 7.93965L6.83333 5.78682C6.35737 5.30929
		5.91632 5.47236 5.69793 5.69147C5.47954 5.91058 5.2697 6.30563 5.79296
		6.83061L7.93873 8.98344L5.79696 11.1323C5.34393 11.5868 5.48292 12.0597 5.69793
		12.2754C5.91294 12.4911 6.3843 12.6306 6.83733 12.1761L8.9791 10.0272L11.1249
		12.1801C11.576 12.6327 12.0486 12.4877 12.2636 12.272C12.4787 12.0563 12.6164
		11.5889 12.1652 11.1363L10.0195 8.98344L12.1692 6.8266ZM9 17C4.58172 17 1 13.4183 1
		9C1 4.58172 4.58172 1 9 1C13.4183 1 17 4.58172 17 9C17 13.4183 13.4183 17 9 17Z" />
</svg>
