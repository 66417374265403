<script>
	export let title = "Search";
</script>

<svg
	xmlns="http://www.w3.org/2000/svg"
	viewBox="0 0 15.24 15.24"
	width="16px"
	role="img">
	<title>{title}</title>
	<path
		d="M15,14,10.77,9.71l0,0a6,6,0,1,0-1.05,1.05l0,0L14,15a.75.75,0,0,0,.53.22A.79.79,0,0,0,15,15,.75.75,0,0,0,15,14ZM1,6a5,5,0,1,1,5,5A5,5,0,0,1,1,6Z" />
</svg>
